<template>
  <div>
    <div class="page-loader" v-show="isLoading"></div>

    <Header/>

    <div class="home container">
      <h1>Pesquisa de Satisfação</h1>

      <div v-if="errors.length" class="container-errors">
        <p v-if="errors.length">
          <b class="padding-bottom-small">Por favor, corrija o(s) seguinte(s) erro(s):</b>
          <li class="text-error" v-for="error in errors">{{ error }}</li>
        </p>
      </div>

      <div class="container-info-support">
        <p><b>Protocolo: </b>{{protocol}}</p>
        <p><b>Nome do Atendente: </b>{{assistantName}}</p>
      </div>

      <label class="label">Como avalia o suporte prestado para o seu atendimento? Informe uma nota de 0 a 10.</label>
      <div  class="container-rate">
        <el-button v-for="item in buttonItems" :type="item==rate ? 'primary' : 'default'" @click="selectRate(item)">{{item}}</el-button>
      </div>

      <label class="label">Você indicaria a Futura Sistemas para um conhecido?</label>
      <el-radio-group class="ftr-radio" v-model="radioRecommendation">
        <el-radio-button label="Sim"></el-radio-button>
        <el-radio-button label="Não"></el-radio-button>
      </el-radio-group>

      <label class="label">Gostaria de deixar algum comentario, sugestão ou critica, para aprimorarmos o nosso atendimento/sistema?</label>
      <el-input
        class="ftr-textarea"
        type="textarea"
        :rows="5"
        placeholder="Comentario, sugestao ou critica que queira nos deixar."
        v-model="textarea">
      </el-input>


      <el-button class="js-btn-enviar" type="primary" @click="setRate()">Enviar</el-button>
    </div>
  </div>

</template>

<script>

import { defineComponent, ref } from 'vue'
import { getInfoByProtocol } from '../api/support'
import { setRateInDatabase, existRate } from '../api/rate'
import Header from '../components/Header'

export default ({
  name: 'Home',

  components: {
    Header
  },

  data(){
    return{
      radioRecommendation: null,
      textarea: ref(''),
      buttonItems: [0,1,2,3,4,5,6,7,8,9,10],
      rate: null,
      assistantName: '',
      protocol: null,
      newSupportId: null,
      isLoading: true,
      errors: [],
    }
  },

  created(){
    const {protocol} = this.getParamsUrl();
    const router = this.$router;
    this.protocol = protocol;

    if(protocol){
      existRate(protocol)
      .then(({data}) => {

        if(data.type == 'success'){
          router.push({name: "Error", params: {typeError : "rateFound"}});
        } else {
          getInfoByProtocol(protocol)
          .then(({data}) => {

            const {result, message, type} = data;

            if(data.type == 'success'){
              const {PROTOCOLO, FANTASIA, ID} = result;

              this.protocol = PROTOCOLO;
              this.newSupportId = ID;
              this.assistantName = FANTASIA;

              this.isLoading = false;
            } else {
              router.push({name: "Error", params: {typeError : "supportError"}});
            }
          })
          .catch((err) => {
            console.log(err, err.response);
            router.push({name: "Error", params: {typeError : "genericError"}});
          });

        }
      })
      .catch((err) => {
        console.log(err, err.response);
        router.push({name: "Error", params: {typeError : "genericError"}});
      });
    } else {
      router.push({name: "Error", params: {typeError : "withoutProtocolError"}});
    }
  },

  methods:{

    setRate(){
      const recomendation = this.radioRecommendation ? this.radioRecommendation[0] : '';

      this.errors = [];

      if (this.rate === null) {
          this.errors.push('A nota é obrigatória.');
      }

      if (!this.radioRecommendation) {
          this.errors.push('A recomendacao é obrigatória.');
      }

      if(this.errors.length > 0){
        return;
      }

      setRateInDatabase(this.protocol, this.newSupportId, this.rate, recomendation, this.textarea)
      .then(({data}) => {
        const router = this.$router;
        router.push("success");
      })
      .catch((err) => {
        console.log(err, err.response);
        router.push({name: "Error", params: {typeError : "setRateError"}});
      });
    },
    selectRate(index){
      this.rate = index;
    },
    getParamsUrl() {
      return window.location.search
        .replace('?', '')
        .split('&')
        .reduce((acc, item) => {
          const [key, value] = item.split('=');
          acc[key] = value;
          return acc;
        }, {});
    }
  }
})

</script>
