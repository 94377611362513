import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import('../views/Success.vue'),
  },
  {
    path: '/error/:typeError',
    name: 'Error',
    component: () => import('../views/Error.vue'),
  },
]


  const routeBasePath = '/'; //AMBIENTE PRODUCAO
  // const routeBasePath = '/pesquisasatisfacao/'; //AMBIENTE TESTE

const router = createRouter({
    //mode: 'history',  //AMBIENTE TESTE
    history: createWebHistory(routeBasePath),
    routes,
})

export default router
