import axios from "axios";

export default axios.create({
  baseURL:process.env.VUE_APP_BASE_URL_API,

  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  },

});

