import axios from '../utils/axios';

export function setRateInDatabase(protocol, clientId, rate, recommendation, comment){
  return axios({
    method: 'post',
    url: '/?route=query/rate&func=setRateInDatabase',
    params: {
      protocol: protocol,
      newSupportId: clientId,
      rate: rate,
      recommendation: recommendation,
      comment: comment,
    }
  });
}

export function existRate(protocol){
  return axios({
    method: 'get',
    url: '/?route=query/rate&func=existRate',
    params: {protocol: protocol}
  });
}
