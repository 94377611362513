<template>
  <router-view/>
</template>

<style>
  *{
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
  }

  h1{
    padding-bottom: 32px;
  }

  #app, textarea{
    font-family: Avenir, Helvetica, Arial, Sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
  }

  .label{
    display: block;
    color: black;
    padding-bottom: 16px;
  }

  .ftr-textarea, .ftr-radio, .container-rate{
    padding-bottom: 32px;
  }

  .container{
    padding-left: 25%;
    padding-right: 25%;
  }

  .container-info-support{
    display: block;
    padding-bottom: 32px;
  }

  #nav {
    padding: 30px;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  }

  .el-button--primary{
    background-color: #337AB7;
  }

  .el-radio-button.is-active .el-radio-button__inner{
    background-color: #337AB7;
  }

  .page-loader {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background: #ffffff url('../src/assets/images/loading-black.svg') center center no-repeat;
  }

  .text-error{
    color: #8A0F1A;
  }

  .container-errors{
    border: #f6d5d8;
    border-radius: 6px;
    background-color: #f6d5d8;
    padding: 24px;
    margin-bottom: 24px;
  }

  .padding-bottom-small{
    display: block;
    padding-bottom: 8px;
  }
</style>
