<template>
    <header>
      <img src="../assets/images/logo.svg">
    </header>
</template>

<script>
    export default {
        name: "Header",

      created(){
        // document.title = 'Futura Sistemas';
      }
    }


</script>

<style scoped>
  header{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }
</style>
